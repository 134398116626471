import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {

  image1: HTMLImageElement = new Image();
  image2: HTMLImageElement = new Image();
  image3: HTMLImageElement = new Image();
  current = 0;
  preloadedImages: any[] = [];
  slideShow: any;
  length = 3;

  constructor() { }

  ngOnInit(): void {
    let preloadedArray: any[] = []; 
    this.image1.src = "../../assets/images/IMG_5133.jpg";
    this.image2.src = "../../assets/images/IMG_5760.jpg";
    this.image3.src = "../../assets/images/IMG_7768.jpg";
    preloadedArray.push(this.image1, this.image2, this.image3);
    this.preloadedImages = preloadedArray;

    this.slideShow = setInterval(
      () => this.nextSlide(),
      7000); 
  }

  ngOnDestroy(): void {
    clearInterval(this.slideShow);
  }

  nextSlide = () => {
    this.current = this.current === this.length - 1 ? 0 : this.current + 1;
  }

  prevSlide = () => {
    this.current = this.current === 0 ? this.length - 1 : this.current - 1;
  }

}

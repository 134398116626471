import { Injectable } from '@angular/core';
import { Feathers } from './feathers.service';
import { Router } from '@angular/router';


/**
 * Abstraction layer for auth. Nice to have when things get more complicated.
 */
@Injectable()
export class AuthService {

  constructor(private feathers: Feathers, private router: Router) {}

 

  public createAccount = async(email: string, password: string) => {
    const userData = {email, password};      
    return await this.feathers.authenticate(Object.assign(userData, {strategy: 'local'}))
 
  }

  login = async (email?: string, password?: string) => {
    try {
      // First try to log in with an existing JWT          
      return await this.feathers._feathers.reAuthenticate();
    } catch (error) {     
      return await this.feathers.authenticate({
        strategy: 'local',
        email,
        password
      });
    }
  };

  //expose LoggedIn userId e.g.
  public getUserId(): string | null{    
      return localStorage.getItem('LoggedUserId');    
  }

  //expose LoggedIn User Observable
  public getUser(){
    let loggedId: string | null = this.getUserId();
    return this.feathers
      .service('users')
      .get(loggedId);
  }

  public async logOut() {
    await this.feathers.logout();
    //localStorage.clear;
    this.router.navigate(['/']);
  };

}

<div class="container mat-app-background">
<form [formGroup]="addressForm" novalidate (ngSubmit)="onSubmit()" >
  <mat-card class="shipping-card">
    <mat-card-header>
      <mat-card-title>Booking Information</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input matInput placeholder="Company" formControlName="company">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input matInput placeholder="First name" formControlName="firstName">
            <mat-error *ngIf="addressForm.controls['firstName'].hasError('required')">
              First name is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="full-width">
            <input matInput placeholder="Last name" formControlName="lastName">
            <mat-error *ngIf="addressForm.controls['lastName'].hasError('required')">
              Last name is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <textarea matInput placeholder="Address" formControlName="address"></textarea>
            <mat-error *ngIf="addressForm.controls['address'].hasError('required')">
              Address is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row" *ngIf="!hasUnitNumber">
        <div class="col">
          <button mat-button type="button" (click)="hasUnitNumber = !hasUnitNumber">
            + Add C/O, Apt, Suite, Unit
          </button>
        </div>
      </div>
      <div class="row" *ngIf="hasUnitNumber">
        <div class="col">
          <mat-form-field class="full-width">
            <textarea matInput placeholder="Address 2" formControlName="address2"></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input matInput placeholder="City" formControlName="city">
            <mat-error *ngIf="addressForm.controls['city'].hasError('required')">
              City is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="full-width">
            <input matInput placeholder="State" formControlName="state">
            <mat-error *ngIf="addressForm.controls['state'].hasError('required')">
              City is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input matInput #postalCode maxlength="5" placeholder="Postal Code" type="number" formControlName="postalCode">
            <mat-hint align="end">{{postalCode.value.length}} / 5</mat-hint>
          </mat-form-field>
        </div>
      </div>
      <div class="row">I am interested in booking Propert A for the following dates:</div>
      <div class="col">
        <div class="custom-form-field">
          <mat-label>From:&nbsp;</mat-label>
          <input matInput [matDatepicker]="picker1" class="my-input" formControlName="startDate" >        
          <mat-datepicker-toggle matSuffix [for]="picker1" class="my-picker"></mat-datepicker-toggle>
          <mat-datepicker #picker1 ></mat-datepicker>
        </div>      
        <div class="custom-form-field">
          <mat-label>To:&nbsp;</mat-label>
          <input matInput [matDatepicker]="picker2" class="my-input" formControlName="endDate" />        
          <mat-datepicker-toggle matSuffix [for]="picker2" class="my-picker"></mat-datepicker-toggle>
          <mat-datepicker #picker2 ></mat-datepicker>
        </div> 
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" type="submit">Submit</button>
    </mat-card-actions>
  </mat-card>
</form>
</div>

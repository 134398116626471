<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="false">
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list>
      <a mat-list-item routerLink="/home">Home</a>
      <a mat-list-item routerLink="property-A">Property A</a>
      <a mat-list-item routerLink="/contactus">Contact us</a>
      <a mat-list-item routerLink="/financials">Financials</a>      
      <a mat-list-item routerLink="/policy">Privacy policy</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content class="content-container">
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"    
        >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span>att-geodiastasi</span>
    </mat-toolbar>
    <div class="screen-container">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

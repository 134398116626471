import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent {
  addressForm = this.fb.group({
    company: null,
    firstName: [null, Validators.required],
    lastName: [null, Validators.required],
    address: [null, Validators.required],
    address2: null,
    city: [null, Validators.required],
    state: [null, Validators.required],
    postalCode: [null, Validators.compose([
      Validators.required, Validators.minLength(5), Validators.maxLength(5)])
    ],
    shipping: ['free', Validators.required],
    startDate: [new Date(), Validators.required],
    endDate: [new Date(), Validators.required]
  });

  hasUnitNumber = false;


  constructor(private fb: UntypedFormBuilder, private dataService: DataService) {}

  onSubmit() {
    let payload =  this.addressForm.value;
    Object.assign(payload, {submissionDate: new Date().toLocaleString()})    
    try{
      this.dataService.createMessage(payload);
      alert('Thanks!');
    }catch (err) {
      console.log(err);
    }  
  }
}

import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import{Router} from '@angular/router';

const defaultEmail = "defaultUser@gmail.com";
const defaultPassword = ")j~nKj/,N}N6,8&cVVV#G!=F*y";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'att-geodiastasi';

  constructor(private authService: AuthService, public router: Router){   
  }

  async ngOnInit(){
    const auth = await this.authService.login(defaultEmail, defaultPassword);     
  }
}

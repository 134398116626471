<div class="container">
    <div>
        <span>Insert a1:</span><input  type = "number" [(ngModel)]="a1"/>
    </div>
    <br/>
    <div>
        <span>Insert aN + 1:</span><input  type = "number" [(ngModel)]="aN"/>
    </div>
    <br/>
    <div>
        <span>Insert aN:</span><input  type = "number" [(ngModel)]="aNminus1"/>        
    </div>
    <br/>
    <div>
        <button (click)="findOmega()"> Calculate omega</button>
    </div>    
    <div>
        <span>Omega is: {{omega}}</span>       
    </div>
    <br/>
    <br/>
    <div>
        <span>Insert N:</span><input  type = "number" [(ngModel)]="N"/>        
    </div>
    <br/>
    <div>
        <button (click)="findNth()"> Calculate {{N}}th a</button>
    </div>    
    <div>
        <span>{{N}}th a is: {{nth}}</span>       
    </div>
    <br/>
    <br/>
    {{textString}}
</div>

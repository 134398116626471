<div class="container mat-app-background">
  <div class="title1-container">
    <p class="title1">Villa in Drosia Evoia</p>   
    <img class="imgElement1" src="../../assets/images/geo-logo.png" alt="" />   
  </div> 
  <p class="title2">300 sq.m. sleeps 2 - 12 persons</p>
  <div class="imgContainer">
    <app-carousel></app-carousel>
  </div> 
  <div style="width:100%;height:390px;margin-top:2vw;">
    <google-map 
      height="380px"
      width="100%"
      [zoom]="15"
      [center]="center"
      [options]="options"
    >
      <map-marker   
        [position]="center"
        [label]="marker?.label"
        [title]="marker?.title"
        [options]="marker?.options"
      >
      </map-marker>
    </google-map>
  </div> 
  <div class="remarks">
    <div>Only phone or mail orders or orders through contact us form are accepted.</div>
    <div>In March 2023 you will be able to book through Booking.com.</div> 
  </div>
  <div class="remarks features">
    <div>Entire villa 300sqm in 2,500 sqm land.</div>
    <div>Six bedrooms for 12 people to sleep.</div>
    <div>50sqm swimming pool equiped with night lights and robotic swiper.</div>
    <div>Dining area, dining table, office</div>
    <div>Four bathrooms fully equiped.</div>
    <div>Kitchen fully equiped with dishwasher, microwave and normal oven.</div> 
    <div>Refrigerator with additional deep freezer.</div>
    <div>Parquet floors.</div>
    <div>6 Air-Conditioning units, heating</div>    
    <div>3 flat screen TVs and free wifi.</div>
    <div>Washing machine, ironing facilities</div>
    <div>Towels, linens, hair dryer.</div>
  </div>
  <div class="remarks pricing">
    <div class="pricing-title">Prices</div>
    <div>November 1st through April 30: 500€ / night.</div>
    <div>May 1st through June 30: 650€ / night.</div> 
    <div>July 1st through August 31: 1000€ / night.</div> 
    <div>September 1st through October 31: 650€ / night.</div> 
  </div>
  <p class="title3">Address: Mesogeion 131 str, Marousi</p>
  <p class="title3">Phone: +30 211 411 5591</p>
  <p class="title3">email: info.geodiastasi@gmail.com</p>
</div>

import { Component, OnInit } from '@angular/core';

declare var google: any;

@Component({
  selector: 'app-home',
  templateUrl: './propertyA.component.html',
  styleUrls: ['./propertyA.component.scss']
})
export class PropertyAComponent implements OnInit {

  
  //center: google.maps.LatLngLiteral;
  center: any;
  options: google.maps.MapOptions = {};
 
  marker: any;

  constructor() { }

  ngOnInit(): void {
    this.center = {
      lat: 38.473917500557114,
      lng: 23.54522090656802,
    };

    this.options = {
      mapTypeId: 'roadmap',
      zoomControl: true,
      scrollwheel: false,
      disableDoubleClickZoom: true,
      maxZoom: 18,
      minZoom: 8,
    }
    this.marker = {         
      label: {
        color: 'black',
        text: 'Customer' ,
      },
      title: 'Phone: 211-4115591' ,
      options: { animation: google.maps.Animation.DROP}, //or BOUNCE
    }  
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './home/home.component';
import {FinancialsComponent} from './financials/financials.component';
import {ContactusComponent} from './contactus/contactus.component';
import { PropertyAComponent } from './propertyA/propertyA.component';
import { PolicyComponent } from './policy/policy.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'property-A', component: PropertyAComponent },
  { path: 'property-B', component: HomeComponent },
  { path: 'property-C', component: HomeComponent },  
  { path: 'contactus', component: ContactusComponent },
  { path: 'financials', component: FinancialsComponent },
  { path: 'policy', component: PolicyComponent },
  { path: '',   redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<div class="container mat-app-background">
  <div class="title1-container">
    <p class="title1">ATT GEODIASTASI M. ΙΚΕ</p>   
    <img class="imgElement1" src="../../assets/images/geo-logo.png" alt="" />   
  </div> 
  <p class="title2">Property short term rental</p>
  <div class="imgContainer">
    <img class="imgElement" src="../../assets/images/terrace.jpg" alt="" />
  </div> 
  <div class="property-button-container">
    <div  routerLink="/property-A" class="property-button">
      <div>Property A</div>
      <div class="price-container">500€ - 1000€</div>
    </div>    
  </div> 
  <p class="title3">Address: Mesogeion 131 str, Marousi</p>
  <p class="title3">Phone: +30 211 411 5591</p>
  <p class="title3">email: info.geodiastasi@gmail.com</p>
</div>

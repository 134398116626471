import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss']
})
export class PolicyComponent implements OnInit {

  safeHtml: SafeHtml ="";
  textString: string = "";
  a1: number = 0;
  aN: number = 0;
  aNminus1: number = 0;
  N: number = 1;
  omega: number = 0;
  nth: number = 0;

 constructor(private sanitizer: DomSanitizer) { }

 findOmega(): void{
  this.omega = this.aN - this.aNminus1;

 }

 findNth(): void{
  this.nth = this.a1 + (this.N - 1) * this.omega;

 }

  ngOnInit(): void {
   // this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(
  this.textString =   `
PRIVACY POLICY:    
For Att-Geodiastasi the protection of personal data is of primary importance and is treated with the utmost seriousness.Respect for the personal data we manage and ensuring their correct processing is one of the Company's priorities. For this reason, we take all the appropriate technical and organizational measures to protect the personal data we process and to ensure that their processing is always carried out in accordance with the requirements set by the current legal framework and mainly by the General Data Protection Regulation (EU ) 2016/679.
1. CONTROLLER – CONTACT DETAILS  Att-Geodiastasi acts as the Controller for all personal data it collects, processes and stores.
Att-Geodiastasi (ΑΤΤ-ΓΕΩΔΙΑΣΤΑΣΗ ΜΟΝΟΠΡΟΣΩΠΗ ΙΚΕ)
Address: Mesogeion 131 str. Marousi, 15126
Phone: 211 4115591
e-mail: info.geodiastasi@gmail.com
Communication Manager: Iosif Christidis.
2. WHAT DATA WE COLLECT AND PROCESS In order to serve you and offer you our services and products, we collect and process all the data you communicate to the call center representative when you place your order or the data you fill in the corresponding fields when you open a user account on our website and more specifically, name, postal address, contact phone number (landline or mobile), e-mail, tax details (in case you wish to issue an invoice).Παρακαλούμε λάβετε υπόψη σας ότι η ιστοσελίδα της Εταιρίας μας ενδέχεται να περιέχει συνδέσμους (links) προς άλλους ιστότοπους. H Εταιρία μας δεν ευθύνεται για τις πρακτικές απορρήτου και προστασίας προσωπικών δεδομένων ή/και το περιεχόμενο αυτών των ιστοτόπων. Αν είστε ηλικίας μέχρι 15 ετών, παρακαλούμε να λάβετε την άδεια του γονέα ή κηδεμόνα σας, προτού μας κοινοποιήσετε οποιαδήποτε προσωπικά στοιχεία σας.
3. PURPOSES OF PROCESSING & LEGAL BASIS OF PROCESSING
All the above personal data are collected and subject to processing for the purpose of, in principle, the execution of the contract between us for the sale of our products and more specifically for the preparation and delivery of your order and our communication with you regarding it. Some of your data may also be used for the following purposes always with your relevant consent:
- Communicating with you in relation to contests, sweepstakes and other events
- Updating and sending offers and informative material about our products and services
- Statistical purposes and surveys
- Promotion and promotion of our products and services
Legal bases for data processing may be as the case may be:
i. The execution and completion of the contract between us for the sale of our products to you (preparation and delivery of your order). Without the collection and use of the above information it will not be possible to serve you
ii. The consent that you may provide to us in order to receive updates regarding offers or promotional material for our products and services, commercial actions and activities that we develop, etc. You can withdraw your consent whenever you wish, by contacting us at contact details mentioned in paragraph 1 or through our website.
4. WHERE WE SHARE YOUR PERSONAL DATA
Your personal data may be shared with the following recipients:
- To a newsletter sending company (provided you have given your consent for us to send you a newsletter)
- To an SMS sending company (in the event that you have given your consent to receive updates)
- To partner companies, in order to be able to receive your gift, if you are a winner in a competition in which you took part (every time we organize a competition, we mention in the terms of the competition the fact of sharing your details with the partner company)
- To partner event organization companies (in case you want to organize a party)
- To an external partner who supports us in software matters
- To the cooperating legal department of the company, in case of a judicial or extrajudicial dispute
- To police or other government authorities if we are asked to do so officially or after a prosecutor's order
5. DATA STORAGE PERIOD
Your data is kept in the Att-Geodiastasi database for 5 years after your last transaction with the company or for as long as the law may require (e.g. in the case of issuing an invoice, as long as the tax legislation defines).
In any case, Att-Geodiastasi implements all appropriate technical and organizational measures to ensure the protection of the personal data it processes and constantly takes care to prevent any unauthorized access to this data.
6. YOUR RIGHTS REGARDING YOUR PERSONAL DATA
Regarding the processing of your personal data you can exercise the following rights:
 Right of access
You have the right to know what data we hold and process about you, why and other additional information about it, as well as to ask us for a copy of it
 Right to rectification
You have the right to request the correction, modification and completion of your personal data
 Right to erasure ("right to be forgotten")
You have the right to request the deletion of your personal data when it is processed after your consent. In cases where the processing is based on another legal basis (such as indicatively the performance of a contract, a legal obligation or the protection of the legal interests of the Company, etc.) your right may be subject to restrictions or may not exist
 Right to restrict processing
You have the right to request the restriction of the processing of your personal data
- when their accuracy is disputed and until the relevant verification is made
- alternatively, instead of deleting them
- when they are no longer necessary for us for the processing purposes for which we collected them, but are necessary for the establishment, exercise or support of legal claims by you
- when you object to their processing and until it is verified that there are legitimate reasons for this processing by the Company
 Right to object to processing
You have the right to object to the processing of your personal data when it is based on a legitimate interest, as well as for direct marketing and profiling purposes
 Right to portability
You have the right to request and receive your personal data in a form that allows you to access it, use it and process it with commonly used processing methods. In addition, for your data that we process by automated means and based on your consent or for the performance of a contract, you can ask us to transfer it directly to another controller, as long as this is technically possible
 Right to withdraw consent
If the processing of your data is based on your consent, you have the right to withdraw it at any time. Withdrawal of your consent does not affect the lawfulness of processing that was based on consent prior to its withdrawal
To exercise your above rights and for any question, complaint or other information regarding the processing of your personal data, you can contact us at the email info.Att-Geodiastasi@gmail.com or at the contact details mentioned in paragraph 1.
7. RIGHTS OF COMPLAINT TO THE AUTHORITY FOR THE PROTECTION OF PERSONAL DATA (APDPH)
You have the right to file a complaint with the Personal Data Protection Authority (www.dpa.gr) regarding matters concerning the processing of your personal data
8. CHANGES TO THE PRIVACY POLICY
Any future changes to our Company's Privacy Policy will be posted on the www.Att-Geodiastasi.gr website and, where required, will be communicated to you.    
    `
    
  //  )
  }

}

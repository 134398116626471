<div class="container mat-app-background">
 
  <h1 class="title">2019</h1>
  <div class="imgElement">
    <embed
      src="../../assets/images/ISOL_ΑΤΤ_2019.pdf"
      type="application/pdf"
      frameBorder="0"
      scrolling="auto"
      height="700px"
      width="1000px"
    /> 
  </div>
  <div class="separator"></div>
  <h1 class="title">2020</h1>
  <div class="imgElement">
    <embed
      src="../../assets/images/ISOL_ΑΤΤ_2020.pdf"
      type="application/pdf"
      frameBorder="0"
      scrolling="auto"
      height="700px"
      width="1000px"
    /> 
  </div>
  <div class="separator"></div>
  <h1 class="title">2021</h1>
  <div class="imgElement">
    <embed
      src="../../assets/images/ISOL_ΑΤΤ_2021.pdf"
      type="application/pdf"
      frameBorder="0"
      scrolling="auto"
      height="700px"
      width="1000px"
    /> 
  </div>
  <div class="separator"></div>
  <h1 class="title">2022</h1>
  <div class="imgElement">
    <embed
      src="../../assets/images/ISOL_ΑΤΤ_2021.pdf"
      type="application/pdf"
      frameBorder="0"
      scrolling="auto"
      height="700px"
      width="1000px"
    /> 
  </div>

</div>
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSliderModule } from '@angular/material/slider';
import { AttnavbarComponent } from './attnavbar/attnavbar.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { HomeComponent } from './home/home.component';
import { FinancialsComponent } from './financials/financials.component';
import { ContactusComponent } from './contactus/contactus.component';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { ReactiveFormsModule } from '@angular/forms';
import { CarouselComponent } from './carousel/carousel.component';
import { PropertyAComponent } from './propertyA/propertyA.component';
import { GoogleMapsModule } from '@angular/google-maps';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { Feathers } from './services/feathers.service';
import { DataService } from './services/data.service';
import { AuthService } from './services/auth.service';
import { PolicyComponent } from './policy/policy.component';


@NgModule({
  declarations: [
    AppComponent,
    AttnavbarComponent,
    HomeComponent,
    PropertyAComponent,
    FinancialsComponent,
    ContactusComponent,
    CarouselComponent,
    PolicyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatSliderModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    ReactiveFormsModule,
    FormsModule,
    GoogleMapsModule,
    MatDatepickerModule,
    MatNativeDateModule
  ], 
  providers: [   
    Feathers,
    DataService,   
    AuthService,
  ],  
  bootstrap: [AppComponent]
})
export class AppModule { }

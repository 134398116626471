<div class='carousel-container' *ngIf="preloadedImages">   
  <div class='arrows-container'>
    <div class='arrow' (click)="prevSlide()"><mat-icon>arrow_back</mat-icon></div>
    <div class='arrow' (click)="nextSlide()"><mat-icon>arrow_forward</mat-icon></div>
  </div> 
  <div>
  <div  *ngFor="let slide of preloadedImages; let i = index" >    
    <div *ngIf="i === current" [ngClass]="i === current ? 'slide active' : 'slide'">
      <img src={{slide.src}} alt="slider" class="image-carousel" />  
    </div>         
  </div>
</div>